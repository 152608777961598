import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { LogoIcon } from '../../../assets/icons/icons';
import smoke from '../../../assets/images/smoke.webp';
import './Location.css';

function Location() {
  return (
    <YMaps>
      <div className="location">
        <LogoIcon
          mainClassName="location__logo"
          fillClassName="location__logo-fill"
          strokeClassName="location__logo-stroke"
          mainFillClassName="location__logo-main-fill"
          mainStrokeClassName="location__logo-main-stroke"
        />
        <p className="location__title">Кутузовский проспект, 36, строение 41</p>
        <p className="location__subtitle">55°74’27.85"N 37°52’72.30"E</p>
        <Map
          className="location__map"
          defaultState={{ center: [55.742785, 37.52723], zoom: 16 }}
        >
          <Placemark
              geometry={[55.742785, 37.52723]}
              properties={{
                  // iconCaption: "Мы находимся здесь", // TODO: place normal text
                  // balloonContent: '<img src="http://img-fotki.yandex.ru/get/6114/82599242.2d6/0_88b97_ec425cf5_M" />'
              }}
              options={{
                iconColor: '#262637FF'
              }}
          />
        </Map>

        <div className="location__circle-box">
          <div className="location__circle" />
        </div>
        <img className="location__bg" src={smoke} alt="" />
      </div>
    </YMaps>
  );
}

export default Location;

import { QRCode } from '../../../assets/icons/icons';
import card1 from '../../../assets/images/card1.webp';
import card2 from '../../../assets/images/card2.webp';
import card3 from '../../../assets/images/card3.webp';
import card4 from '../../../assets/images/card4.webp';
import './ImagesGrid.css';

const imgsList = [
  {
    img: card1,
  },
  {
    img: card2,
    icon: QRCode,
  },
  {
    img: card3,
  },
  {
    img: card4,
  },
];

function ImagesGrid() {
  return (
    <div className="imgs-grid">
      <ul className="imgs-grid__list">
        {imgsList.map((item, i) => (
          <li className="imgs-grid__item" key={i}>
            <img className="imgs-grid__img" src={item.img} alt="" />
            {item.icon
              ? item.icon({
                  mainClassName: 'imgs-grid__icon',
                  fillClassName: 'imgs-grid__icon-fill',
                })
              : null}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ImagesGrid;

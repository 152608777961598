import './Header.css';

function Header() {
  return (
    <header className="header">
      <h1 className="header__title">ЛАУНДЖ-БАР</h1>
      <a className='header__phone' href='tel:+79672399992' target='_blank' rel='noreferrer'>+7 (967) 239-99-92</a>
    </header>
  );
}

export default Header;

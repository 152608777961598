import { ArrowIcon, LogoTextIcon } from '../../../assets/icons/icons';
import banner from '../../../assets/images/banner.webp';
import smoke from '../../../assets/images/smoke.webp';
import './Banner.css';

function Banner() {
  return (
    <div className="banner">
      <img className="banner__img" src={banner} alt="" />
      <LogoTextIcon
        mainClassName="banner__logo"
        firstFillClassName="banner__logo-fill-top"
        secondFillClassName="banner__logo-fill-bottom"
      />
      <div className="banner__arrow-box">
        <ArrowIcon
          mainClassName="banner__arrow"
          fillClassName="banner__arrow-fill"
        />
      </div>

      <div className="banner__bg">
        <div className="banner__circle-box banner__circle-box_position_left">
          <div className="banner__circle" />
          <div className="banner__fill-circle banner__fill-circle_position_left" />
        </div>

        <div className="banner__circle-box banner__circle-box_position_center">
          <div className="banner__circle" />
        </div>

        <div className="banner__circle-box banner__circle-box_position_right">
          <div className="banner__circle" />
          <div className="banner__fill-circle banner__fill-circle_position_right" />
        </div>
      </div>

      <img className="banner__smoke-bg" src={smoke} alt="" />
    </div>
  );
}

export default Banner;

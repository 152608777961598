import {
  InsIcon,
  PhoneIcon,
  PlaceIcon,
  SiteIcon,
  TelegramIcon,
  WhatsAppIcon,
} from '../icons/icons';

export const LINKS_LIST = [
  {
    icon: TelegramIcon,
    data: 'Telegram',
    href: 'https://t.me/unionhookah99',
  },
  {
    icon: WhatsAppIcon,
    data: 'WhatsApp',
    href: 'https://wa.me/79672399992',
  },
  // {
  //   icon: InsIcon,
  //   data: 'Запретограм',
  //   href: 'https://www.instagram.com/unionlounge99/',
  // },
  {
    icon: PhoneIcon,
    data: '+7 (967) 239-99-92',
    href: 'tel:+79672399992',
  },
  {
    icon: SiteIcon,
    data: 'Сайт',
    link: '/',
  },
  {
    icon: PlaceIcon,
    data: 'Кутузовский проспект, 36, строение 41',
    href: 'https://yandex.ru/maps/org/soyuz_99/156329403318/?ll=37.527230%2C55.742785&z=17.14',
  },
];

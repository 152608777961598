import { ArrowIcon } from '../../../assets/icons/icons';
import './TextBlock.css';

function TextBlock({ par, title, text, withLink = false }) {
  return (
    <div
      className={`text-template ${
        withLink ? 'text-template_type_with-link' : ''
      }`}
    >
      <div className="text-template__par-box">
        <p className="text-template__par">{par}</p>
        <div className="text-template__divider" />
      </div>

      <div className="text-template__content">
        {title ? <p className="text-template__title">{title}</p> : null}
        {text ? <p className="text-template__text">{text}</p> : null}
        {withLink ? (
          <a
            className="text-template__link-btn"
            href="https://menu.union99.ru"
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-template__link-text">Меню</p>
            <ArrowIcon
              mainClassName="text-template__link-arrow"
              fillClassName="text-template__link-arrow-fill"
            />
          </a>
        ) : null}
      </div>
    </div>
  );
}

export default TextBlock;

import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.webp';
import banner from '../../assets/images/banner.webp';
import smoke from '../../assets/images/smoke.webp';
import './Links.css';
import { LINKS_LIST } from '../../assets/utils/constants';
import { StripesIcon } from '../../assets/icons/icons';

function Links() {
  return (
    <section className="links">
      <img className="links__logo" src={logo} alt="" />
      <div className="links__content">
        <ul className="links__list">
          {LINKS_LIST.map((item) => (
            <li className="links__item" key={item.data}>
              {item.link ? (
                <Link className="links__link" to={item.link}>
                  {item.icon({
                    mainClassName: 'links__link-icon',
                    fillClassName: 'links__link-icon-fill',
                  })}
                  <p className="links__link-text">{item.data}</p>
                </Link>
              ) : (
                <a
                  className="links__link"
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.icon({
                    mainClassName: 'links__link-icon',
                    fillClassName: 'links__link-icon-fill',
                  })}
                  <p className="links__link-text">{item.data}</p>
                </a>
              )}
            </li>
          ))}
        </ul>

        <a className="links__btn" href="https://menu.union99.ru/" target="_blank" rel="noreferrer">
          Меню
        </a>
      </div>

      <div className="links__footer">
        <StripesIcon
          mainClassName="links__footer-bg"
          strokeClassName="links__footer-bg-stroke"
        />
        <div className="links__footer-gradient">
          <p className="links__footer-text">
            Лаунж-бар СОЮЗ 99. © Все права защищены.
            <br />
            2024
          </p>
        </div>
      </div>

      <img className="links__bg-img" src={banner} alt="" />
      <div className="links__circle-box links__circle-box_position_left">
        <div className="links__circle" />
        <div className="links__fill-circle" />
      </div>
      <div className="links__circle-box links__circle-box_position_center">
        <div className="links__circle" />
      </div>
      <img className="links__smoke-bg" src={smoke} alt="" />
    </section>
  );
}

export default Links;

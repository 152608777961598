import { useEffect, useState } from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Banner from './Banner/Banner';
import ImagesGrid from './ImagesGrid/ImagesGrid';
import './Landing.css';
import Location from './Location/Location';
import TextBlock from './TextBlock/TextBlock';
import SubmitActionPopup from './SubmitActionPopup/SubmitActionPopup';

function Landing() {
  const [isSubmitPopupOpen, setSubmitPopupOpen] = useState(false);


  useEffect(() => {
    const age_submited = localStorage.getItem('age_submited')
    if (!age_submited) {
      setSubmitPopupOpen(true)
    }
  }, [])

  function hendleAgeSubmit() {
    localStorage.setItem('age_submited', true)
    setSubmitPopupOpen(false)
  }

  return (
    <main className="landing">
      <SubmitActionPopup

        hendleSubmit={hendleAgeSubmit}
        hendleClose={() => {
          window.location.href = 'https://youtu.be/e_04ZrNroTo'
        }}
        isOpen={isSubmitPopupOpen}
      />
      <Header />
      <Banner />
      <TextBlock
        par="КОНЦЕПТ"
        title="В какой-то дымке
        матовой Земля
        в&nbsp;иллюминаторе..."
        text="«Союз-99» — лаундж-бар, сочетающий в&nbsp;себе ретрофутуризм и&nbsp;современность. Барная карта, меню кальянов и&nbsp;закусок представлены в&nbsp;нашем оригинальном прочтении. Переступая порог «Союза-99», вы попадаете
        в&nbsp;ретрокосмическую лабораторию, главная задача которой — создать для вас комфорт и&nbsp;оставить незабываемые впечатления."
      />
      <ImagesGrid />
      <TextBlock
        par="МЕНЮ"
        text="Наш ориентир — вкусы тех, кому мы доверяем, —
        наших&nbsp;гостей. Так появился список уникальных кальянов, коктейлей и&nbsp;закусок, который постоянно дополняется.
        Мы&nbsp;будем рады услышать о том, что любите вы, и воссоздать это в космическом исполнении."
        withLink
      />
      <Location />
      <Footer />
    </main>
  );
}

export default Landing;
